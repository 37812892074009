import {
 useEffect, useState, useCallback, useRef,
} from 'react';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';

// eslint-disable-next-line
const useCombinedHits = (expertsIndexName, pitchesIndexName, productsIndexName, pressReleasesIndexName, searchClient, query) => {
  const [combinedHits, setCombinedHits] = useState([]);
  const [asyncError, setAsyncError] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  // Separate page states for each index
  const [pages, setPages] = useState({
    experts: 0,
    pitches: 0,
    pressReleases: 0,
    products: 0,
  });

  const isFetchingRef = useRef(false);
  const mountedRef = useRef(true);
  const prevQueryRef = useRef(query);

  // Define desired hits per index based on percentages
  const TOTAL_HITS_PER_PAGE = 8; // Adjust as needed
  const desiredRatios = {
    experts: 0.375, // 37.5%
    pitches: 0.25, // 25%
    pressReleases: 0.25, // 25%
    products: 0.125, // 12.5%
  };

  const hitsPerIndex = {
    experts: Math.round(TOTAL_HITS_PER_PAGE * desiredRatios.experts),
    pitches: Math.round(TOTAL_HITS_PER_PAGE * desiredRatios.pitches),
    pressReleases: Math.round(TOTAL_HITS_PER_PAGE * desiredRatios.pressReleases),
    products: Math.round(TOTAL_HITS_PER_PAGE * desiredRatios.products),
  };

  const fetchAugmentedHits = useCallback(async (hits, fetchFn, trackName) => {
    const ids = hits.map((hit) => hit.objectID);
    if (ids.length > 0) {
      try {
        vahoy.track(trackName, { requestids: ids });
        const response = await fetchFn(ids);
        if (response.status === 200) {
          const results = response.data.data;
          return hits.map((hit) => {
            const additionalData = results.find((data) => data.id === hit.objectID);
            return additionalData
              ? { ...hit, ...additionalData.attributes }
              : hit;
          });
        }
      } catch (error) {
        setAsyncError(error);
      }
    }
    return hits.map((hit) => ({ ...hit }));
  }, []);

  const fetchHits = useCallback(
    async (currentQuery, currentPages) => {
      if (isFetchingRef.current) return;
      isFetchingRef.current = true;

      try {
        // Prepare individual queries with specific hitsPerPage and page
        const queries = [
          {
            indexName: expertsIndexName,
            query: currentQuery,
            hitsPerPage: hitsPerIndex.experts,
            page: currentPages.experts,
          },
          {
            indexName: pitchesIndexName,
            query: currentQuery,
            hitsPerPage: hitsPerIndex.pitches,
            page: currentPages.pitches,
          },
          {
            indexName: productsIndexName,
            query: currentQuery,
            hitsPerPage: hitsPerIndex.products,
            page: currentPages.products,
          },
          {
            indexName: pressReleasesIndexName,
            query: currentQuery,
            hitsPerPage: hitsPerIndex.pressReleases,
            page: currentPages.pressReleases,
          },
        ];

        const { results } = await searchClient.search(queries);

        // Fetch and augment hits per index
        const experts = await fetchAugmentedHits(
          results[0].hits,
          vapi.getAlgoliaAttribsForSourceIds,
          'SourcesSearch#getAlgoliaAttribs',
        );
        const pitches = await fetchAugmentedHits(
          results[1].hits,
          async () => ({ status: 200, data: { data: [] } }),
          'PitchesSearch#getAlgoliaAttribs',
        );
        const products = await fetchAugmentedHits(
          results[2].hits,
          vapi.getAlgoliaAttribsForProductIds,
          'ProductsSearch#getAlgoliaAttribs',
        );
        const pressReleases = await fetchAugmentedHits(
          results[3].hits,
          async () => ({ status: 200, data: { data: [] } }),
          'PressReleasesSearch#getAlgoliaAttribs',
        );

        // Combine hits maintaining the desired order
        const newHits = [{ type: 'query', query: currentQuery }];
        newHits.push(
          ...experts.map((hit) => ({ ...hit, type: 'expert' })),
          ...pitches.map((hit) => ({ ...hit, type: 'pitch' })),
          ...pressReleases.map((hit) => ({ ...hit, type: 'press_release' })),
          ...products.map((hit) => ({ ...hit, type: 'product' }))
        );

        if (mountedRef.current) {
          setCombinedHits((prevHits) => (currentPages.experts === 0 ? newHits : [...prevHits, ...newHits]));

          // Determine if there's more data to fetch
          const hasMoreData = results[0].nbHits > (currentPages.experts + 1) * hitsPerIndex.experts
                              || results[1].nbHits > (currentPages.pitches + 1) * hitsPerIndex.pitches
                              || results[2].nbHits > (currentPages.products + 1) * hitsPerIndex.products
                              || results[3].nbHits > (currentPages.pressReleases + 1) * hitsPerIndex.pressReleases;

          setHasMore(hasMoreData);
        }
      } catch (error) {
        if (mountedRef.current) setAsyncError(error);
      } finally {
        isFetchingRef.current = false;
      }
    },
    [
      expertsIndexName,
      pitchesIndexName,
      productsIndexName,
      pressReleasesIndexName,
      searchClient,
      fetchAugmentedHits,
      hitsPerIndex.experts,
      hitsPerIndex.pitches,
      hitsPerIndex.products,
      hitsPerIndex.pressReleases,
    ],
  );

  useEffect(() => {
    if (prevQueryRef.current !== query) {
      prevQueryRef.current = query;
      setPages({
        experts: 0,
        pitches: 0,
        pressReleases: 0,
        products: 0,
      });
      setCombinedHits([]); // Reset hits when query changes
      fetchHits(query, {
        experts: 0, pitches: 0, pressReleases: 0, products: 0,
      });
    }
  }, [fetchHits, query]);

  const refineNext = useCallback(() => {
    setPages((prevPages) => ({
      experts: prevPages.experts + 1,
      pitches: prevPages.pitches + 1,
      pressReleases: prevPages.pressReleases + 1,
      products: prevPages.products + 1,
    }));
  }, []);

  useEffect(() => {
    // Fetch next page if pages have incremented
    if (pages.experts > 0 || pages.pitches > 0 || pages.pressReleases > 0 || pages.products > 0) {
      fetchHits(query, pages);
    }
  }, [fetchHits, pages, query]);

  useEffect(() => () => {
    mountedRef.current = false;
  }, []);

  if (asyncError) throw asyncError;

  return {
    hits: combinedHits,
    hasMore,
    refineNext,
  };
};

export default useCombinedHits;
