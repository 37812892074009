import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';

function ReportModal({
                       abusive_behavior_types: abusiveBehaviorTypes,
                       behavior_types: behaviorTypes,
                       close_modal: closeModal,
                       handle_description_change: handleDescriptionChange,
                       set_behavior_types: setBehaviorTypes,
                       show_modal: showmodal,
                       submit_report: submitReport,
                     }) {
  const toggleBehaviorType = (key) => {
    const newBehaviorTypes = [...(behaviorTypes || [])];
    if (newBehaviorTypes.includes(key)) {
      const index = newBehaviorTypes.indexOf(key);
      newBehaviorTypes.splice(index, 1);
    } else {
      newBehaviorTypes.push(key);
    }
    setBehaviorTypes(newBehaviorTypes);
  };

  return (
    <Modal className="modal-lg" isOpen={showmodal} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        <div className="h3 text-danger"><i className="fa-solid fa-triangle-exclamation" />&nbsp;Report this pitch anonymously</div>
      </ModalHeader>

      <ModalBody>
        <div className="alert alert-danger">
        Qwoted reviews all reported pitches and takes action ranging from a gentle email to account disablement for
        off-topic, spammy, AI-generated, or otherwise inappropriate content. Your report is kept confidential and
        anonymous. Please share why you're reporting this pitch:
        </div>
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <p className="mb-2">Please select those which describe the inappropriate behavior:</p>
              {Object.keys(abusiveBehaviorTypes).map((key) => (
                <button
                  className={`btn btn-sm me-2 mb-2 ${behaviorTypes && behaviorTypes.includes(key) ? 'btn-secondary' : 'btn-outline-secondary'}`}
                  key={key}
                  onClick={() => toggleBehaviorType(key)}
                  type="button"
                >
                  {abusiveBehaviorTypes[key]}
                </button>
              ))}
            </div>
            <textarea
              id="description"
              className="form-control"
              name="description"
              placeholder="Please elaborate on your concerns."
              onChange={handleDescriptionChange}
              rows="10"
              required
            />
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color="danger" onClick={submitReport}>Report</Button>
        <Button onClick={closeModal}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}

ReportModal.propTypes = {
  abusive_behavior_types: PropTypes.object,
  behavior_types: PropTypes.array,
  close_modal: PropTypes.func.isRequired,
  handle_description_change: PropTypes.func.isRequired,
  set_behavior_types: PropTypes.func.isRequired,
  show_modal: PropTypes.bool,
  submit_report: PropTypes.func.isRequired,
};

ReportModal.defaultProps = {
  show_modal: false,
  abusive_behavior_types: undefined,
  behavior_types: undefined,
};

export default ReportModal;
