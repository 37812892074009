import React from 'react';
import PropTypes from 'prop-types';

import Hashtags from '../../hashtags';
import SmartHighlight from '../../algolia_search/smart_highlight';
import SnippetHtmlEntities from '../../algolia_search/snippet_html_entities';
import dateHelpers from '../../../javascript/frontend/helpers/date_helpers';
import vahoy from '../../../javascript/vahoy';

function PressReleaseCard({ pressRelease }) {
  const trackClicks = () => {
    vahoy.track('PressRelease/PressReleaseCard#clickPressReleaseLink');
  };

  return (
    <div
      className="card h-100 press-release-card animated faster fadeIn"
      id={`press-release-${pressRelease.objectID}`}
    >
      <div className="card-body position-relative" style={{ display: 'flex', flexDirection: 'column' }}>
        <a href={pressRelease.press_release_path} onClick={trackClicks} className="text-decoration-none">
          <h2 style={{ fontSize: '1.25rem' }} className="card-title mb-3">
            <SmartHighlight attribute="title" hit={pressRelease} />
          </h2>
        </a>

        <p className="card-text mb-3">
          {pressRelease.first_image_url && (
            <img
              src={pressRelease.first_image_url}
              alt=""
              className="me-2"
              style={{ float: 'left', objectFit: 'cover' }}
              width="125px"
              height="125px"
              onError={
                (event) => {
                  // eslint-disable-next-line no-param-reassign
                  event.target.style.display = 'none';
                }
              }
            />
          )}
          <SnippetHtmlEntities attribute="content" hit={pressRelease} />
          <div style={{ marginTop: '0.5rem', clear: 'both' }} />
        </p>

        {pressRelease.hashtags.length > 0 && (
          <div className="mb-3">
            <Hashtags
              hashtagTarget={pressRelease}
              maxHashtags={5}
              clickEventName="PressReleases/PressReleaseCard#clickHashtag"
            />
          </div>
        )}

        <div className="clearfix" style={{ minHeight: '4rem' }} />

        <div className="mb-3" style={{ position: 'absolute', bottom: '0' }}>
          <small className="text-muted">
            {pressRelease.company_mentioned && (
              <>
                {/* TODO: Linking this back to a search should maybe be included in the Algolia saved URL PR */}
                <strong>Company Mentioned: </strong>{pressRelease.company_mentioned}
                <br />
              </>
            )}
            <strong>Date Published: </strong>{dateHelpers.formatDatetime(pressRelease.published_at, 'MMM D, Y')}
            <br />
            <strong>Published By: </strong><a target="_blank" href={`//${pressRelease.site_url}`} rel="noreferrer">{pressRelease.site_name}</a>
          </small>
        </div>
      </div>
    </div>
  );
}

PressReleaseCard.propTypes = {
  pressRelease: PropTypes.instanceOf(Object),
};

PressReleaseCard.defaultProps = {
  pressRelease: undefined,
};

export default PressReleaseCard;
