import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import PitchTextEditor from './PitchTextEditor';
import PitchLibrary from './PitchLibrary';
import AttachEntityModal from './attach_entity/AttachEntityModal';
import ActionButtons from './ActionButtons';
import FileInput from '../../file_input';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function PitchComposer({
                         addExistingEntityToPitch,
                         addNewEntityToPitch,
                         attachedEntities,
                         attachedExistingFiles,
                         conversationOnly,
                         deleteExistingEntityFromPitch,
                         deleteNewEntity,
                         pitchText: pText,
                         pitchPublicizable,
                         setAttachedFileUploads,
                         setCompositionStarted,
                         setPitchPublicizable,
                         updateAndSendPitch,
                         requestId,
                       }) {
  const [pitchText, setPitchText] = useState(pText);
  const [pitchTextEdited, setPitchTextEdited] = useState(false);

  // Callback for editing pitch text
  const handlePitchTextEdit = (text) => {
    setPitchTextEdited(true);
    setPitchText(text);
    setCompositionStarted(true);
  };

  const discardEdits = () => {
    setPitchTextEdited(false);
    setPitchText('');
    setCompositionStarted(false);
    // onDiscard();
  };

  // Toggle the pitchPublicizable state
  const togglePitchPublicizable = () => {
    setPitchPublicizable(!pitchPublicizable);
  };

  return (
    <div>
      {attachedEntities?.length > 0 && (
        <PitchLibrary
          attachedEntities={attachedEntities}
          setPitchText={setPitchText}
          pitchTextEdited={pitchTextEdited}
          discardEdits={discardEdits}
        />
      )}

      <PitchTextEditor
        pitchText={pitchText}
        setPitchText={handlePitchTextEdit}
        pitchTextEdited={pitchTextEdited}
        attachedEntities={attachedEntities}
        deleteExistingEntityFromPitch={deleteExistingEntityFromPitch}
        deleteNewEntity={deleteNewEntity}
        requestId={requestId}
      />

      <div className="mb-2" />

      <AttachEntityModal
        addExistingEntityToPitch={addExistingEntityToPitch}
        addNewEntityToPitch={addNewEntityToPitch}
        attachedEntities={attachedEntities}
      />
      <div className="mb-3">
        <FileInput
          multiple
          existingFiles={attachedExistingFiles}
          onChange={(files) => setAttachedFileUploads(files)}
        />
      </div>
      {!conversationOnly
        && (
        <div className="mb-2 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="pitchPublicizableCheck"
            checked={pitchPublicizable}
            onChange={togglePitchPublicizable}
          />
          <label className="form-check-label" htmlFor="pitchPublicizableCheck">
            Amplify this pitch - create a Story Idea for relevant reporters
            <UncontrolledTooltip target="amplify-tooltip">
              Maximize your visibility by sending out a pitch summary and increase chances of direct
              outreach from targeted reporters working on tight deadlines.
            </UncontrolledTooltip>
            <span id="amplify-tooltip">
            &nbsp;<i className="fa-solid fa-circle-info text-info" />
          </span>
          </label>
        </div>
)}
      <ActionButtons onClick={() => updateAndSendPitch(pitchText)} onDiscard={() => discardEdits()} />
    </div>
  );
}

PitchComposer.propTypes = {
  addExistingEntityToPitch: PropTypes.func.isRequired,
  addNewEntityToPitch: PropTypes.func.isRequired,
  attachedEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
  attachedExistingFiles: PropTypes.arrayOf(PropTypes.object),
  conversationOnly: PropTypes.bool.isRequired,
  deleteExistingEntityFromPitch: PropTypes.func.isRequired,
  deleteNewEntity: PropTypes.func.isRequired,
  // onDiscard: PropTypes.func.isRequired,
  pitchText: PropTypes.string.isRequired,
  pitchPublicizable: PropTypes.bool.isRequired,
  setAttachedFileUploads: PropTypes.func.isRequired,
  setCompositionStarted: PropTypes.func.isRequired,
  setPitchPublicizable: PropTypes.func.isRequired,
  updateAndSendPitch: PropTypes.func.isRequired,
  requestId: PropTypes.number.isRequired,
};

PitchComposer.defaultProps = {
  attachedExistingFiles: [],
};

export default PitchComposer;
