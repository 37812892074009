import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import vapi from '../javascript/frontend/api/vapi';

function InboxIconWithUnreadCount({
                                    active, link, mobile,
                                  }) {
  const [asyncError, setAsyncError] = useState();
  const [numUnreadConversations, setNumUnreadConversation] = useState(false);

  useEffect(() => {
    const getMessagingInbox = async () => {
      try {
        vapi.getMessagingInboxPresent()
          .then((response) => {
            const result = response.data;
            setNumUnreadConversation(result.num_unread_conversations);
          });
      } catch (error) {
        setAsyncError(error);
      }
    };
    getMessagingInbox();
  }, []);

  if (asyncError) throw asyncError;

  return (
    <li className="nav-item">
      <a href={link} className={`nav-link ${mobile ? 'nav-link-mobile' : ''} ${active ? 'active' : ''}`}>
        {numUnreadConversations > 0 && (
          <>
            <span className="badge rounded-pill bg-primary" style={{ position: 'relative', top: '-2px' }}>
              {numUnreadConversations}
            </span>&nbsp;
          </>
        )}

        {mobile && (
          <><i className="fa fa-inbox" /> Inbox</>
        )}
        {!mobile && (
          <i className="fa-solid fa-inbox fa-lg" />
        )}
      </a>
    </li>
  );
}

InboxIconWithUnreadCount.propTypes = {
  active: PropTypes.bool,
  link: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
};

InboxIconWithUnreadCount.defaultProps = {
  active: false,
  mobile: false,
};

export default InboxIconWithUnreadCount;
