import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

function SectionDistribution({
                               dontPromoteOutside,
                               requestType,
                               setDontPromoteOutside,
                             }) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (requestType === 'story_ideas') {
    return null;
  }

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const handleChange = (e) => {
    const value = e.target.checked;
    setDontPromoteOutside(value);
  };

  return (
    <div className="mb-3">
      <div className="fw-bold">Distribution</div>
      <label className="form-check ps-0" style={{ display: 'inline' }}>
        <input
          id="dontPromote"
          type="checkbox"
          className="me-2"
          defaultChecked={dontPromoteOutside}
          onInput={(e) => handleChange(e)}
        />
        <span htmlFor="dontPromote">
          Do not promote this request on Qwoted's social
        </span>
        <small>
          <button
            id="dontPromotePopover"
            type="button"
            className="btn btn-link ps-0 pt-0 ms-1"
          >
            <i className="fa-solid fa-circle-question" />
          </button>

          <Popover
            placement="auto"
            isOpen={popoverOpen}
            target="dontPromotePopover"
            toggle={togglePopover}
          >
            <PopoverHeader>What does this mean?</PopoverHeader>
            <PopoverBody>
              Boost your story's reach! We can share an anonymized version of your request on our social feeds,
              helping more experts discover and engage with your idea.
              Prefer to keep it off social? Simply check this box.
            </PopoverBody>
          </Popover>
        </small>
      </label>
    </div>
  );
}

SectionDistribution.propTypes = {
  dontPromoteOutside: PropTypes.bool,
  requestType: PropTypes.string,
  setDontPromoteOutside: PropTypes.func,
};

SectionDistribution.defaultProps = {
  dontPromoteOutside: undefined,
  requestType: undefined,
  setDontPromoteOutside: undefined,
};

export default SectionDistribution;
